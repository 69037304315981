.user-homepage {
    background-color: var(--background-color);
    padding: var(--spacing-lg);
  }
  
  .user-avatar {
    border: 2px solid var(--secondary-color);
    border-radius: 50%;
    transition: transform var(--transition-speed) ease;
  }
  
  .user-avatar:hover {
    transform: scale(1.05);
  }
  
  .user-name {
    font-size: 1.5rem;
    font-weight: bold;
    color: var(--text-color);
    margin: var(--spacing-md) 0;
  }
  
  .notice-item {
    cursor: pointer;
    transition: all var(--transition-speed) ease;
    padding: var(--spacing-md);
    border-radius: var(--border-radius-sm);
    border: 1px solid var(--border-color);
    margin-bottom: var(--spacing-sm);
  }
  
  .notice-item:hover {
    background-color: var(--background-color);
    transform: translateX(var(--spacing-xs));
    border-color: var(--secondary-color);
  }
  
  table {
    text-align: center;
    width: 100%;
    border-collapse: collapse;
    margin: var(--spacing-lg) 0;
  }
  
  table th {
    background-color: var(--primary-color);
    color: white;
    padding: var(--spacing-md);
  }
  
  table td {
    padding: var(--spacing-md);
    border-bottom: 1px solid var(--border-color);
  }
  
  .user-homepage h4 {
    border-bottom: 2px solid var(--secondary-color);
    padding-bottom: var(--spacing-sm);
    margin-bottom: var(--spacing-lg);
    color: var(--primary-color);
  }
  
  p {
    margin-bottom: var(--spacing-sm);
    color: var(--text-light);
  }
  
  /* 添加响应式设计 */
  @media (max-width: 768px) {
    .user-homepage {
      padding: var(--spacing-md);
    }
    
    table {
      font-size: 14px;
    }
    
    table th,
    table td {
      padding: var(--spacing-sm);
    }
  }
  