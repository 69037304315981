/* AdminDashboard.css */

.chart-container {
    margin-top: var(--spacing-lg);
    padding: var(--spacing-lg);
    box-shadow: var(--shadow-md);
    border-radius: var(--border-radius-md);
    background-color: white;
    transition: transform var(--transition-speed) ease, box-shadow var(--transition-speed) ease;
}

.chart-container:hover {
    transform: translateY(-2px);
    box-shadow: var(--shadow-lg);
}

h1 {
    font-size: 2.5rem;
    color: var(--primary-color);
    margin-bottom: var(--spacing-lg);
    text-align: center;
    position: relative;
}

h1::after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
    width: 100px;
    height: 3px;
    background-color: var(--secondary-color);
    border-radius: var(--border-radius-sm);
}

.info-block {
    background-color: white;
    padding: var(--spacing-lg);
    border-radius: var(--border-radius-md);
    text-align: center;
    margin-bottom: var(--spacing-lg);
    box-shadow: var(--shadow-sm);
    border: 1px solid var(--border-color);
    transition: all var(--transition-speed) ease;
}

.info-block:hover {
    box-shadow: var(--shadow-md);
    border-color: var(--secondary-color);
}

.info-block p {
    font-size: 1.25rem;
    color: var(--text-light);
    margin-bottom: var(--spacing-sm);
}

.info-block p span {
    font-weight: bold;
    font-size: 1.5rem;
    color: var(--secondary-color);
    display: block;
    margin-top: var(--spacing-xs);
}

/* 让图表在移动端自适应 */
@media (max-width: 768px) {
    .chart-container {
        width: 100%;
        height: 300px;
        padding: var(--spacing-md);
    }

    h1 {
        font-size: 2rem;
    }

    .info-block {
        padding: var(--spacing-md);
    }
}

@media (min-width: 769px) {
    .chart-container {
        width: 100%;
        height: 400px;
    }
}
  