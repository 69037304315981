.login-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100vw;
  background: #f5f5f5;
  overflow-x: hidden;
  position: fixed;
  top: 0;
  left: 0;
}

.login-content {
  flex: 1;
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  padding: 40px 20px;
}

.login-left {
  width: 35%;
  margin: 0 1%;
}

.login-logo {
  width: 100%;
  max-width: 300px;
  margin: 0 auto 40px;
  display: block;
}

.feature-list {
  width: 100%;
}

.feature-item {
  margin-bottom: 24px;
}

.feature-item h4 {
  margin-bottom: 12px;
  color: #1890ff;
}

.feature-item .ant-typography {
  margin: 0;
  color: #666;
}

.login-right {
  width: 35%;
  margin: 0 1%;
  background-color: white;
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0,0,0,0.1);
}

.login-options {
  margin-bottom: 24px;
}

.login-links {
  display: flex;
  justify-content: space-between;
  margin-top: 12px;
}

.login-links a {
  color: #1890ff;
}

.login-footer {
  width: 100%;
  text-align: center;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 -2px 8px rgba(0,0,0,0.05);
  border-top: 1px solid #f0f0f0;
}

.login-footer .ant-typography {
  font-size: 14px;
  color: #666;
}

.login-footer a {
  color: #1890ff;
  font-weight: 500;
}

.divider {
  margin: 0 8px;
  color: #999;
}

.version {
  cursor: pointer;
  color: #1890ff;
  font-size: 13px;
  font-weight: 500;
  opacity: 0.85;
}

@media screen and (max-width: 768px) {
  .login-container {
    position: fixed;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 16px;
    background: #f5f5f5;
  }

  .login-content {
    flex: none;
    width: 100%;
    height: auto;
    padding: 0;
    overflow: visible;
  }

  .login-left {
    display: none;
  }

  .login-right {
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
    padding: 24px;
    box-shadow: 0 2px 8px rgba(0,0,0,0.08);
  }

  .ant-pro-form-login-container {
    min-height: auto !important;
    padding: 0 !important;
  }

  .ant-pro-form-login-main {
    width: 100% !important;
    min-width: auto !important;
    max-width: none !important;
    padding: 0 !important;
    margin: 0 !important;
  }

  .ant-pro-form {
    margin: 0 !important;
    padding: 0 !important;
  }

  .ant-form {
    padding: 0 !important;
    margin: 0 !important;
  }

  .ant-pro-form-login-title {
    font-size: 20px;
    text-align: center;
    margin-bottom: 8px !important;
  }

  .ant-pro-form-login-subtitle {
    font-size: 14px;
    text-align: center;
    margin-bottom: 24px !important;
  }

  .login-footer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 10px;
    background: #fff;
    box-shadow: 0 -2px 8px rgba(0,0,0,0.05);
    z-index: 1000;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .ant-pro-form-login-main {
    width: 100%;
    max-width: none;
  }

  .login-options {
    margin-bottom: 16px;
  }

  .login-links {
    margin-top: 16px;
  }

  .login-footer {
    position: fixed;
    bottom: 0;
    left: 0;
    padding: 12px;
    font-size: 12px;
    z-index: 100;
  }

  .login-footer .ant-typography,
  .login-footer a,
  .login-footer .version {
    font-size: 12px;
  }

  .divider {
    margin: 0 4px;
  }
}
  